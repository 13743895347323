import { BoundNumberField, Css } from "@homebound/beam";
import {
  EstimateSection,
  EstStatContainer,
  EstSingleStat,
  EstSingleChildStat,
} from "src/routes/cma/steps/estimate/components";
import React from "react";
import { EstimateFormState } from "src/routes/cma/steps/estimate/estimate-forms/EstimateForm";
import { UnderwritingReport } from "src/routes/cma/endpoints/reports";

type ReturnsAndMarginFieldsProps = {
  report: UnderwritingReport;
  estimateFormState: EstimateFormState;
};

export function ReturnsAndMarginFields({ report, estimateFormState }: ReturnsAndMarginFieldsProps) {
  return (
    <EstimateSection title="Returns & Margins" forceOpen>
      <div>
        <EstStatContainer title="Gross Profit" type="money" value={report.costs_and_metrics?.gross_profit}>
          <EstSingleChildStat
            title="Gross Profit Margin:"
            value={report.costs_and_metrics?.gross_profit_margin}
            type="percentage"
          />
        </EstStatContainer>
        <EstStatContainer title="Operating Profit" type="money" value={report.costs_and_metrics?.operating_profit}>
          <EstSingleChildStat
            title="Operating Profit Margin"
            value={report.costs_and_metrics?.operating_profit_margin}
            type="percentage"
          />
        </EstStatContainer>
      </div>
      <div>
        <EstSingleStat bold title="Net Profit" value={report.costs_and_metrics?.investor_net_profit} type="money" />
        <EstStatContainer
          bold
          title="Net Investor Margin"
          type="percentage"
          value={report.costs_and_metrics?.investor_margin}
        >
          <EstSingleChildStat
            highlight={!estimateFormState.readOnly}
            title="Target:"
            value={
              <div css={Css.maxwPx(75).$}>
                <BoundNumberField
                  label="Investor Margin Target"
                  labelStyle="hidden"
                  field={estimateFormState.costs_and_metrics.backsolve_investor_margin_investor_margin}
                  type="percent"
                  numFractionDigits={2}
                  // Inherit font styles when field becomes readonly
                  xss={Css.tac.color("inherit").fw("inherit").lh("inherit").add("fontSize", "inherit").$}
                  compact
                />
              </div>
            }
            type="percentage"
          />
        </EstStatContainer>
      </div>
      <div>
        <EstSingleStat
          bold
          title="Unlevered IRR"
          value={
            report.costs_and_metrics?.unlevered_internal_rate_of_return_basis_points
              ? report.costs_and_metrics.unlevered_internal_rate_of_return_basis_points / 100_00
              : "-"
          }
          type="percentage"
        />
        <EstSingleStat
          bold
          title="Return On Cost (Unlevered)"
          value={
            report.costs_and_metrics?.unlevered_return_on_cost_basis_points
              ? report.costs_and_metrics.unlevered_return_on_cost_basis_points / 100_00
              : "-"
          }
          type="percentage"
        />
        <EstStatContainer
          bold
          title="Return On Cost (Senior Only)"
          value={
            report.costs_and_metrics?.levered_return_on_cost_basis_points
              ? report.costs_and_metrics.levered_return_on_cost_basis_points / 100_00
              : "-"
          }
          type="percentage"
        >
          <EstSingleChildStat title="Max LTC" value={0.6} type="percentage" />
          <EstSingleChildStat title="Land LTC" value={0.25} type="percentage" />
          <EstSingleChildStat
            title="Senior Debt Interest"
            value={report.costs_and_metrics?.senior_debt_interest || 0}
            type="money"
          />
        </EstStatContainer>
        <EstSingleStat bold title="Direct Margin" value={report.costs_and_metrics?.direct_margin} type="percentage" />
      </div>
    </EstimateSection>
  );
}
