import { UnderwritingReport } from "src/routes/cma/endpoints/reports";
import { Property } from "src/routes/cma/endpoints";
import { Css, Palette } from "@homebound/beam";
import React, { Fragment, useMemo, useState } from "react";
import { EstimateSection } from "src/routes/cma/steps/estimate/components";
import { NumberField } from "@homebound/beam/dist/inputs/NumberField";
import { formatNumberToString } from "src/utils";

// Investor Margin Sensitivity Contingency table
export function IMSensitivitySection({ report, property }: { report: UnderwritingReport; property: Property }) {
  const [lowThreshold, setLowThreshold] = useState(8.0);
  const [highThreshold, setHighThreshold] = useState(10.0);
  const { net_sale_revenue, total_costs, hard_costs, build_margin, final_estimate_contingency_price_in_cents } =
    report.costs_and_metrics!;
  const contingencyPrice = final_estimate_contingency_price_in_cents / 100;
  const contingencyBudget = (contingencyPrice * hard_costs) / (hard_costs + build_margin);
  const priceWithoutContinency = hard_costs + build_margin - contingencyPrice;
  const totalBuildableSqft = useMemo(() => {
    return report.ready_plans!.reduce((sum, current_item) => {
      return sum + current_item.sellable_sqft * current_item.copies;
    }, 0);
  }, [report]);

  const expectedSalePrice = report.costs_and_metrics?.exp_price_with_appreciation ?? 0;
  const contingencyVarients = [
    contingencyPrice * -1,
    (contingencyPrice / 2) * -1,
    0,
    contingencyPrice / 2,
    contingencyPrice,
  ];

  return (
    <EstimateSection title="Investor Margin Sensitivity" numColumns={10}>
      <div css={Css.df.fdc.gap1.$}>
        <div css={Css.df.aic.jcc.smBd.tdu.$}>Hard Cost Variance (+ Builder Fee Knock on Effects)</div>
        <div css={Css.dg.gtc("repeat(7, 1fr)").$}>
          <ContingencyTextCell start text={`Contingency in budget: $${formatNumberToString(contingencyBudget)}`} />
          <ContingencyTextCell text="0%" />
          <ContingencyTextCell text="50%" />
          <ContingencyTextCell text="100%" />
          <ContingencyTextCell text="150%" />
          <ContingencyTextCell text="200%" />
          <div css={Css.df.aic.jcc.tac.fw6.gc("span 2").$}>Sales Price Variance</div>
          {contingencyVarients.map((cv, cvIdx) => {
            return (
              <div key={`contingency-variant-${cv}-${cvIdx}`} css={Css.df.aic.jcc.bb.pPx(4).$}>
                <span css={Css.if(cvIdx === 2).fw7.blue700.$}>
                  {formatNumberToString((cv * 100) / priceWithoutContinency) + "%"}
                </span>
              </div>
            );
          })}
          {[-0.05, -0.025, 0, 0.025, 0.05].map((salePriceVariant) => {
            const onePlusSalePriceVariant = 1 + salePriceVariant;
            const adjRevenue = net_sale_revenue * onePlusSalePriceVariant;
            return (
              <Fragment key={`sale-price-variant-${salePriceVariant}`}>
                <SalePriceVarianceCell
                  salePercentVariant={salePriceVariant}
                  expectedSalePriceVariant={expectedSalePrice * onePlusSalePriceVariant}
                  expectedSqFtVariant={(expectedSalePrice * onePlusSalePriceVariant) / totalBuildableSqft}
                />
                {contingencyVarients.map((contingencyUsed, contingencyUsedIdx) => {
                  const adjCost = total_costs + contingencyUsed;
                  const adjHardCostVariance = ((adjRevenue - adjCost) / adjRevenue) * 100;
                  return (
                    <ContingencyVarianceCell
                      key={`contingency-data-cell-${salePriceVariant}-${contingencyUsed}-${contingencyUsedIdx}`}
                      adjHardCostVariance={adjHardCostVariance}
                      highThreshold={highThreshold}
                      lowThreshold={lowThreshold}
                    />
                  );
                })}
              </Fragment>
            );
          })}
          <div css={Css.df.fdrr.gap3.pt2.add("gridColumnStart", 4).add("gridColumnEnd", "span 4").$}>
            <div css={Css.df.aic.sm.$}>
              <span css={Css.wsnw.pr2.$}>Dark Green {">="}</span>
              <NumberField
                compact
                sizeToContent
                labelStyle="hidden"
                label="Dark Green >="
                value={highThreshold}
                onChange={(v) => {
                  setHighThreshold(v ?? 0);
                }}
                type={"percent"}
                numFractionDigits={2}
              />
            </div>
            <div css={Css.df.aic.sm.$}>
              <span css={Css.wsnw.pr2.$}>Light Green {">="}</span>
              <NumberField
                compact
                sizeToContent
                labelStyle="hidden"
                label="Light Green >="
                value={lowThreshold}
                onChange={(v) => {
                  setLowThreshold(v ?? 0);
                }}
                type={"percent"}
                numFractionDigits={2}
              />
            </div>
          </div>
        </div>
      </div>
    </EstimateSection>
  );
}

// Spans 2 columns
function SalePriceVarianceCell({
  salePercentVariant,
  expectedSalePriceVariant,
  expectedSqFtVariant,
}: {
  salePercentVariant: number;
  expectedSalePriceVariant: number;
  expectedSqFtVariant: number;
}) {
  return (
    <div
      css={
        Css.dg
          .gtc("1fr 1fr 1fr")
          .tac.pyPx(4)
          .gap1.br.add("gridColumnEnd", "span 2")
          .if(salePercentVariant === 0).fw7.blue700.$
      }
    >
      <div>${formatNumberToString(expectedSqFtVariant, true, true)}</div>
      <div>${formatNumberToString(expectedSalePriceVariant, true, true)}</div>
      <div>{formatNumberToString(salePercentVariant * 100) + "%"}</div>
    </div>
  );
}

function ContingencyVarianceCell({
  adjHardCostVariance,
  lowThreshold,
  highThreshold,
}: {
  adjHardCostVariance: number;
  lowThreshold: number;
  highThreshold: number;
}) {
  const cellColor =
    adjHardCostVariance < lowThreshold
      ? Palette.Transparent
      : adjHardCostVariance >= highThreshold
        ? Palette.Green600
        : Palette.Green300;

  return <div css={Css.df.jcc.pPx(4).bgColor(cellColor).$}>{formatNumberToString(adjHardCostVariance) + "%"}</div>;
}

function ContingencyTextCell({ text, start = false }: { text: string; start?: boolean }) {
  return (
    <div
      css={
        Css.df.fdc.aic.jcc
          .mxPx(1)
          .py1.bsDashed.bb.if(start)
          .add("gridColumnEnd", "span 2")
          .tac.if(start)
          .fw7.else.add("fontStyle", "italic").fw5.gray600.$
      }
    >
      <div>{text}</div>
      {!start && <div>Contingency</div>}
    </div>
  );
}
